/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query malaga {
    file(relativePath: { eq: "parking-barcelona-el-prat-front.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  template: "index-page",
  slug: "/",
  title: "Parkingi Lotnisko Barcelona El Prat Hiszpania",
  tagline: "Barcelona El Prat, Hiszpania",
  featuredImage: "/assets/parking-barcelona-el-prat-front.jpeg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
}
const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <p>
        <strong>Adres:</strong> Aeropuerto de Barcelona-El Prat, 08820 El Prat
        de Llobregat, Barcelona, Spain.
        <p>
          Jeśli planujesz podróż do Barcelony samochodem i szukasz miejsca,
          gdzie bezpiecznie zostawisz swój pojazd podczas podróży, dobrą opcją
          może być skorzystanie z jednego z licznych parkingów znajdujących się
          w bezpośrednim sąsiedztwie lotniska Barcelona El Prat.
        </p>
      </p>
    }
  >
    <h2 id="parkingi-przy-lotnisku-barcelona-el-prat">
      Parkingi przy lotnisku Barcelona El Prat
    </h2>

    <h3 id="bezpieczeństwo-i-wygoda">Bezpieczeństwo i wygoda</h3>
    <p>
      Nasza oferta obejmuje zarówno krótko-, jak i długoterminowe miejsca
      parkingowe, które są monitorowane i strzeżone przez całą dobę. Wszystko po
      to, aby nasza usługa była dla Ciebie nie tylko wygodna, ale także pewna i
      bezpieczna.
    </p>
    <h3 id="elastyczność">Elastyczność</h3>
    <p>
      Znajdziesz u nas najbardziej konkurencyjne ceny i elastyczne opcje
      rezerwacji, które można dostosować do Twoich potrzeb i planów podróży. Bez
      względu na to, na jak długo wybierasz się na wakacje czy służbową podróż,
      mamy dla Ciebie odpowiednie rozwiązanie.
    </p>
    <h3 id="łatwa-rezerwacja-online">Łatwa rezerwacja online</h3>
    <p>
      Z naszym systemem rezerwacji online wszystko jest proste i szybkie.
      Wystarczy podać datę przyjazdu i wyjazdu, a my zarezerwujemy dla Ciebie
      miejsce parkingowe. Po potwierdzeniu rezerwacji otrzymasz od nas e-mail z
      wszystkimi niezbędnymi informacjami.
    </p>
    <h3 id="dostępność">Dostępność</h3>
    <p>
      Parkingi przy lotnisku Barcelona El Prat są dostępne 24/7, więc
      niezależnie od godziny Twojego lotu, zawsze znajdziesz u nas wolne
      miejsce.
    </p>
    <h3 id="dodatkowe-usługi">Dodatkowe usługi</h3>
    <p>
      Oferujemy szereg dodatkowych usług, takich jak mycie samochodu czy serwis
      mechaniczny, dzięki czemu Twój samochód będzie gotowy do dalszej podróży
      od razu po Twoim powrocie.
    </p>
    <h2 id="transfer-z-lotniska">Transfer z lotniska</h2>
    <p>
      Aerodrom Barcelona El Prat jest zaledwie 10 km od stolicy Katalonii.
      Chciałbyś wiedzieć, jak dotrzeć z El Prat do centrum Barcelony? Najlepszym
      wyjściem są tu lotniskowe autobusy &quot;Aerobus&quot;. Przystanki metra
      L9 Sud są dostępne przy obu terminalach lotniska BCN, a także kilka linii
      autobusów miejskich. Chcąc podróżować dalej niż do Barcelony, masz
      możliwość skorzystania z międzymiastowych oraz międzynarodowych połączeń
      autokarowych. Ale uwaga! W razie przylotu na lotnisko El Prat w porze
      nocnej, do miasta dostaniesz się wyłącznie taksówkami czekającymi przy
      obiektach obu terminali lub jedną z trzech nocnych linii autobusowych.
    </p>
    <h1 id="udogodnienia-dla-turystów">Udogodnienia dla turystów</h1>
    <p>
      Gdy musisz przesiąść się na lotnisku El Prat na inny terminal, skorzystaj
      z bezpłatnego autobusu kursującego tu bez przerwy. Niczym jednak nie
      przejmuj się! Dojazd pomiędzy różnymi częściami lotniska Barcelona może
      zająć do 15 minut. Jeśli dysponujesz więcej czasem przed odlotem z
      lotniska w Barcelonie, posmakuj tradycyjnych tapas w jednym z licznych
      punktów gastronomicznych, a może nawet spróbuj wyrazistego, hiszpańskiego
      wina. Co ciekawe, oba terminale lotniska El Prat mają bardzo zbliżoną gamę
      usług i sklepów. Expressing a need for something special? Gorąco polecamy
      spróbować jednego z zabiegów w lotniskowym salonie SPA lub luksusowym
      gabinecie kosmetycznym - idealnym rozwiązaniu dla relaksu przed długim
      lotem!
    </p>
  </Homepage>
)

export default HomePage
